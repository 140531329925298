import React from "react"
import Image from "gatsby-image";
import "../styles/about.scss"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const description = "Zakład wykonuje usługi kuśnierskie, krawieckie, tapicerskie, rymarskie i kaletnicze. Przeróbki krawieckie. Wieloletnie, bogate doświadczenie. Konin"
const keywords = ["kuśnierz konin"]
const AboutPage = ({ data, pathname }) => (
    <>
        <SEO
            title="O firmie"
            description={description}
            keywords={keywords}
        // pathname={"o-firmie"}

        />
        <section className="aboutPage container">
            <h2>O FIRMIE</h2>
            <div className="descContainer">
                <div className="imgContainer">
                    <Image fluid={data.machine.childImageSharp.fluid} />
                    <div className="border"></div>
                </div>
                <div className="textContainer">
                    <div className="main">
                        Usługi kuśnierskie, krawieckie, tapicerskie, rymarskie i kaletnicze
                </div>
                    <div className="extra">
                        Nasza firma posiada wieloletnie doświadczenie oraz bogatą ofertę usług. Gwarantujmy fachowość, jakość, atrakcyjne ceny oraz profesjonalne doradztwo.
                </div>
                    <div className="smallBorder"></div>
                </div>
            </div>
            <div className="aboutServices">
                <div className="title">
                    Pracownia cieszy się od lat ogromnym szacunkiem i sympatią klientów
            </div>
                <div className="desc">
                    <p>Specjalizujemy się we wszelkiego rodzaju przeróbkach i naprawach. Posiadane doświadczenie oraz zakres świadczonych usług, pozwalają nam sprostać każdemu zleceniu.</p>
                    <p>Zwężamy, skracamy oraz poszerzamy ubrania. Naprawiamy, czyścimy, poddajemy renowacji, szyjemy nowe oraz przerabiamy skórzaną odzież. Zajmujemy się również szeroko pojętym tapicerstwem meblowym, samochodowym, medycznym, a także jachtowym.</p>
                    <p>Stosujemy materiały własne i powierzone przez klienta. Posiadamy szeroki wybór tkanin oraz skór. </p>
                </div>
            </div>
            <div className="aboutGallery">
                <div className="chair"><Image fluid={data.armChair.childImageSharp.fluid} /></div>
                <div className="furr"><Image fluid={data.furrier.childImageSharp.fluid} /></div>
                <div className="gloves"><Image fluid={data.gloves.childImageSharp.fluid} /></div>
                <div className="bag"><Image fluid={data.bag.childImageSharp.fluid} /></div>
            </div>
            <div className="cooperation">
                <h2>WSPÓŁPRACA</h2>
                <p>Serdecznie zapraszamy do współpracy wszelkie restauracje, bary, hotele, firmy budowlane i remontowe oraz wszystkich innych, którzy potrzebują pomocy w naprawie i konserwacji mebli i odzieży.</p>
            </div>
        </section>

    </>

)
export const query = graphql`
  {
    machine: file(name: {eq: "machineImage"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    armChair: file(name: {eq: "about_armchair"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    gloves: file(name: {eq: "about_gloves"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    furrier: file(name: {eq: "about_furrier"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    bag: file(name: {eq: "about_bag"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    }

  }
`
export default AboutPage
